/* TODO: Update to theme media query variable once that is working on NCOM */
/* https://noa-mktg.atlassian.net/browse/WDEV-5238 */

.wrapper {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #f9f9fa 0%, #fff 100%);
  padding: var(--theme-spacing-32) var(--theme-spacing-16);

  @media (min-width: 1025px) {
    border-radius: var(--border-radius);
    max-width: 71.5rem;
    background: var(--theme-colors-ui-bgAccent);
    margin: var(--theme-spacing-80) auto var(--theme-spacing-40);
  }
}

.header {
  display: flex;
  align-items: center;
  gap: var(--theme-spacing-8);
  margin-bottom: var(--theme-spacing-8);

  @media (min-width: 1025px) {
    gap: var(--theme-spacing-16);
    margin-bottom: var(--theme-spacing-16);
  }
}

.icon {
  height: 32px;
  width: 32px;
  color: var(--theme-color-primary);
}

.title {
  font-size: var(--theme-font-size-bodyMobile);
  font-weight: var(--theme-font-weight-bold);

  @media (min-width: 1025px) {
    font-size: var(--theme-font-size-h2Desktop);
  }
}
