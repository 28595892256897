.modal {
  text-align: center;
}

.heading {
  background-color: var(--theme-color-primary);
  padding: var(--theme-spacing-16);
  color: var(--theme-color-white);
  font-weight: var(--theme-font-weight-bold);
}

.headingText {
  max-width: 324px;
  margin: 0 auto;

  @media (min-width: 1025px) {
    font-size: 18px;
  }
}

.subheading {
  padding: var(--theme-spacing-24);
  max-width: 475px;
  margin: 0 auto;
}

.subheadingTitle {
  font-size: var(--theme-font-size-h3Desktop);
  font-weight: var(--theme-font-weight-bold);
  margin-bottom: var(--theme-spacing-16);
}

.subheadingText {
  font-size: var(--theme-font-size-bodyMobile);
}

.graphic {
  padding: var(--theme-spacing-16);
  background-color: var(--theme-colors-ui-bgAccent);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: var(--theme-spacing-8);

  @media (min-width: 1025px) {
    gap: var(--theme-spacing-12);
    margin: 0 var(--theme-spacing-24);
    border-radius: var(--border-radius);
  }
}

.graphicItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80px;
  gap: var(--theme-spacing-8);

  @media (min-width: 1025px) {
    max-width: 112px;
  }
}

.borderRadius {
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  @media (min-width: 1025px) {
    border-radius: var(--border-radius-small);
  }
}

.keyArt {
  height: 80px;
  width: 80px;
  overflow: hidden;
  flex-shrink: 0;

  @media (min-width: 1025px) {
    height: 112px;
    width: 112px;
  }
}

.symbol {
  font-weight: var(--theme-font-weight-bold);
  font-size: 22px;
  font-weight: 800;
  align-self: center;
  position: relative;
  top: -16px;

  @media (min-width: 1025px) {
    top: -20px;
  }
}

.footer {
  padding: var(--theme-spacing-24) var(--theme-spacing-24)
    var(--theme-spacing-32);
  display: flex;
  flex-direction: column;
  gap: var(--theme-spacing-16);
  font-size: var(--theme-font-size-bodyMobile);

  @media (min-width: 1025px) {
    font-size: var(--theme-font-size-bodyDesktop);
  }
}

.shadow {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.ownedItem {
  margin-top: var(--theme-spacing-16);

  @media (min-width: 1025px) {
    margin-top: var(--theme-spacing-20);
  }
}

.subtext {
  font-size: var(--theme-font-size-legalMobile);
  font-weight: 600;
  margin-top: var(--theme-spacing-8);
  margin-bottom: 0;

  @media (min-width: 1025px) {
    font-size: var(--theme-font-size-legalDesktop);
  }
}

.tag {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 7px;
  font-weight: var(--theme-font-weight-bold);
  color: var(--theme-color-white);
  background: rgba(0, 0, 0, 0.6);
  padding: var(--theme-spacing-4) var(--theme-spacing-4);

  @media (--theme-mediaQuery-desktop) {
    padding-left: var(--theme-spacing-8);
    padding-right: var(--theme-spacing-8);
    font-size: var(--theme-font-size-legalMobile);
  }
}
