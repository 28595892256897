.versionOptions {
  margin-bottom: var(--theme-spacing-16);
}

.upgradeSection {
  margin-top: var(--theme-spacing-12);

  @media (min-width: 1025px) {
    margin-top: var(--theme-spacing-16);
  }
}

.upgradeLabel {
  display: flex;
  align-items: center;
  gap: var(--theme-spacing-8);
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  color: var(--theme-color-primary);
  margin-top: var(--theme-spacing-16);
  font-size: var(--theme-font-size-captionMobile);
  font-weight: 600;
  text-decoration: underline;
  transition: color 200ms ease-in-out;

  /* TODO: Update to theme media query variable once that is working on NCOM */
  /* https://noa-mktg.atlassian.net/browse/WDEV-5238 */
  @media (min-width: 1025px) {
    font-size: var(--theme-font-size-captionDesktop);
  }
}

.upgradeLabel:hover {
  color: var(--theme-color-primaryHover);
}
